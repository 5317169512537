import React, { Component } from "react"
import { Link } from "gatsby"
import QRCode from "qrcode"
import get from "lodash.get"
import memoize from "lodash.memoize"
import jsQR from "jsqr"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import Layout from "../components/layout"
import AutoResizeCanvas from "../components/auto-resize-canvas"
import firebase from "../utils/firebase"
import withAuth from "../utils/with-auth"

dayjs.extend(relativeTime)

if (!global.window) {
  global.window = {
    document: {
      createElement: () => {},
      addEventListener: () => {},
      hidden: undefined,
      msHidden: undefined,
      webkitHidden: undefined,
    },
  }
}

const STATUS = {
  APPROVED: "approved",
  REJECTED: "rejected",
  LOADING: "loading",
  PROCESSING: "processing",
  READY: "read",
}
const RESET_DELAY = 1500 // ms
const ASPECT_RATIO = 4 / 3 // 1.3333333
const STREAM_PADDING = 40 // px

const STATUS_COLORS = {
  [STATUS.APPROVED]: "#9cf196",
  [STATUS.REJECTED]: "#ed1250",
  [STATUS.LOADING]: "white",
  [STATUS.READY]: "white",
  [STATUS.PROCESSING]: "#ebce95",
}

/**
 * Some of the code to handle scanning has been copied from the jsQR example.
 * Seen here: https://cozmo.github.io/jsQR/
 */
@withAuth
class IndexPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      ticketCode: null,
      status: STATUS.LOADING,
      ticketDetails: {},
    }

    this.videoElement = null
    this.canvasElement = React.createRef()

    this.currentScan = null
    this.handleScanning = this.handleScanning.bind(this)
    this.handleScannerReset = this.handleScannerReset.bind(this)
    this.handleStartStream = this.handleStartStream.bind(this)
    this.handleStopStream = this.handleStopStream.bind(this)
    this.handleTabFocus = this.handleTabFocus.bind(this)

    this.imageStream = null
    this.browserVideoStream = null
    this.possibleTicketCode = null
    this.canvas = null
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      const { ticketCode } = this.state
      if (ticketCode !== prevState.ticketCode && !!ticketCode) {
        cancelAnimationFrame(this.currentScan)

        this.setState(
          {
            status: STATUS.PROCESSING,
            message: null,
          },
          () => {
            try {
              const [ticketId, ticketIndex] = ticketCode.split("-")

              const ticketDocRef = firebase
                .firestore()
                .collection("tickets")
                .doc(ticketId)

              firebase
                .firestore()
                .runTransaction(function(currentTransaction) {
                  // This code may get re-run multiple times if there are conflicts.
                  return currentTransaction
                    .get(ticketDocRef)
                    .then(function(ticketDoc) {
                      if (!ticketDoc.exists) {
                        throw "Sorry, that ticket appears to be invalid. Ask the customer to re-open the ticket from the email they received."
                      }

                      const data = ticketDoc.data()
                      const currentTicket = get(data, `tickets.${ticketIndex}`)

                      if (!get(currentTicket, "checkedIn")) {
                        let updatedTickets = data.tickets
                        updatedTickets[ticketIndex] = {
                          ...currentTicket,
                          checkedIn: true,
                          checkInTime: firebase.firestore.Timestamp.fromDate(
                            new Date()
                          ),
                        }

                        currentTransaction.update(ticketDocRef, {
                          tickets: updatedTickets,
                        })
                        return {
                          ...{
                            ...data,
                            tickets: updatedTickets,
                          },
                          valid: true,
                        }
                      } else {
                        currentTransaction.update(ticketDocRef, {})
                        return data
                      }
                    })
                })
                .then(response => {
                  this.setState(
                    {
                      status: response.valid
                        ? STATUS.APPROVED
                        : STATUS.REJECTED,
                      ticketDetails: response,
                    },
                    this.handleScannerReset
                  )
                })
                .catch(error => {
                  this.setState(
                    { status: STATUS.REJECTED, message: error.message },
                    this.handleScannerReset
                  )
                })
            } catch (error) {
              this.setState(
                {
                  status: STATUS.REJECTED,
                  message:
                    "Sorry, that ticket does not appear to be generate from this system. Please make sure you are using the correct ticket. Or try to re-open it from the e-mail we sent you.",
                },
                this.handleScannerReset
              )
            }
          }
        )
      }
    } catch (error) {
      console.error("ERROR: ", error)
    }
  }

  componentDidMount() {
    import('../assets/check-in.scss');

    try {
      /**
       * This has been done so we re-use the same variable.
       */
      if (!this.canvas) {
        this.canvas = this.canvasElement.current.getContext("2d")
      }

      // Fetch all ticketTypes
      firebase
        .firestore()
        .collection("ticketTypes")
        .get()
        .then(documentSnapshot => {
          let currentTypes = []
          documentSnapshot.forEach(document => {
            currentTypes.push({ data: document.data(), id: document.id })
          })

          this.setState({ ticketTypes: currentTypes })
        })

      this.handleStartStream()
      this.handleTabFocus()
    } catch (error) {
      console.error("ERROR: ", error)
    }
  }

  componentWillUnmount() {
    window &&
      window.document.removeEventListener(
        this.currentVisibilityChange,
        this.currentHandleVisibilityChange
      )
    this.handleStopStream()
  }

  handleStopStream() {
    cancelAnimationFrame(this.currentScan)
    this.browserVideoStream &&
      this.browserVideoStream.getTracks().forEach(track => track.stop())
    this.browserVideoStream = null
    this.videoElement = null
    this.imageStream = null
    this.possibleTicketCode = null
    this.canvas = null
  }

  handleStartStream() {
    try {
      this.canvas = this.canvasElement.current.getContext("2d")
      this.videoElement = window && window.document.createElement("video")
      // Use facingMode: environment to attemt to get the front camera on phones
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then(stream => {
          this.browserVideoStream = stream
          this.videoElement.srcObject = this.browserVideoStream
          // required to tell iOS safari we don't want fullscreen
          this.videoElement.setAttribute("playsinline", true)

          this.videoElement
            .play()
            .then(response => {
              this.currentScan = requestAnimationFrame(this.handleScanning)
            })
            .catch(error => {
              throw new Error("Can't autoplay")
            })
        })
        .catch(() => {
          this.setState({
            error: {
              message: (
                <div>
                  Sorry, your camera doesn't appear to be working at the moment.
                  Please restart your device or disconnect the camera and
                  reconnect it.
                </div>
              ),
            },
          })
        })
    } catch (error) {
      console.error("ERROR: ", error)
    }
  }

  handleScanning() {
    try {
      const { ticketCode, status } = this.state

      /**
       * NOTE: We check that we have the canvas setup before we start processing the video feed
       */
      if (
        this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA &&
        !!this.canvas
      ) {
        this.canvasElement.current.height = this.videoElement.videoHeight
        this.canvasElement.current.width = this.videoElement.videoWidth

        /**
         * Draw the incoming video to canvas so we can show it to the user
         */
        this.canvas.drawImage(
          this.videoElement,
          0,
          0,
          this.canvasElement.current.width,
          this.canvasElement.current.height
        )

        /**
         * Fetch the image data so we can find a QR code in it
         */
        this.imageStream = this.canvas.getImageData(
          0,
          0,
          this.canvasElement.current.width,
          this.canvasElement.current.height
        )

        /**
         * Check for the QR code
         */
        this.possibleTicketCode = jsQR(
          this.imageStream.data,
          this.imageStream.width,
          this.imageStream.height,
          {
            inversionAttempts: "dontInvert",
          }
        )

        if (this.possibleTicketCode && !ticketCode) {
          this.setState({
            ticketCode: get(this.possibleTicketCode, "data"),
            codeData: this.possibleTicketCode,
          })
        } else if (!ticketCode && status !== STATUS.READY) {
          this.setState({ status: STATUS.READY })
        }
      }

      this.currentScan = requestAnimationFrame(this.handleScanning)
    } catch (error) {
      console.error("ERROR: ", error)
    }
  }

  handleScannerReset() {
    const { status } = this.state
    setTimeout(
      () => {
        this.setState({ ticketCode: null }, () => {
          this.currentScan = requestAnimationFrame(this.handleScanning)
        })
      },
      status === STATUS.REJECTED ? 4000 : RESET_DELAY
    )
  }

  handleTabFocus() {
    try {
      // Set the name of the hidden property and the change event for visibility
      var hidden, visibilityChange
      if (typeof window.document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden"
        visibilityChange = "visibilitychange"
      } else if (typeof window.document.msHidden !== "undefined") {
        hidden = "msHidden"
        visibilityChange = "msvisibilitychange"
      } else if (typeof window.document.webkitHidden !== "undefined") {
        hidden = "webkitHidden"
        visibilityChange = "webkitvisibilitychange"
      }

      // If the page is hidden, pause the video;
      // if the page is shown, play the video
      const handleVisibilityChange = () => {
        if (window.document[hidden]) {
          this.handleStopStream()
        } else {
          this.handleStartStream()
        }
      }

      // Warn if the browser doesn't support addEventListener or the Page Visibility API
      if (
        typeof window.document.addEventListener === "undefined" ||
        hidden === undefined
      ) {
        console.log(
          "This browser does not support the Visibility API. We won't attempt to restart the stream when focus is lost."
        )
        // TODO: Show warning to the user when they first open the check-in page
      } else {
        this.currentVisibilityChange = visibilityChange
        this.currentHandleVisibilityChange = handleVisibilityChange
        // Handle page visibility change
        window &&
          window.document.addEventListener(
            visibilityChange,
            handleVisibilityChange,
            false
          )
      }
    } catch (error) {
      console.error("ERROR: ", error)
    }
  }

  render() {
    const { error, status } = this.state

    return (
      <Layout withNav isLoading={status === STATUS.LOADING}>
        {error ? (
          error.message
        ) : (
          <div
            style={{
              backgroundColor: STATUS_COLORS[status],
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <span
              style={{
                fontWeight: 900,
                fontSize: "22px",
                paddingBottom: "15px",
                paddingTop: "30px",
              }}
            >
              {status === STATUS.READY && "Ready to scan"}
              {status === STATUS.PROCESSING && "Checking ticket..."}
              {status === STATUS.LOADING && "Loading..."}
              {status === STATUS.APPROVED && "Approved"}
              {status === STATUS.REJECTED && "Rejected"}
            </span>

            <AutoResizeCanvas
              ref={this.canvasElement}
              aspectRatio={ASPECT_RATIO}
              padding={STREAM_PADDING}
            />

            {this.renderLastTicket()}
          </div>
        )}
      </Layout>
    )
  }

  renderLastTicket() {
    const { ticketDetails, status, ticketCode, ticketTypes } = this.state
    const [ticketId, ticketIndex] = (ticketCode || "").split("-")

    const scannedTicket = get(ticketDetails, `tickets.${ticketIndex}`)

    const hasDetails = !!scannedTicket
    const isProcessing = status === STATUS.PROCESSING
    if (isProcessing || !hasDetails) {
      return null
    }

    const customerName = get(ticketDetails, "customer.name")
    const typeName = get(
      ticketTypes.find(type => type.id === get(scannedTicket, "type.id")),
      "data.name"
    )
    const isValid = get(ticketDetails, "valid")
    const checkInTime = get(scannedTicket, "checkInTime.seconds")
    const formattedCheckInTime = checkInTime
      ? dayjs.unix(checkInTime).from(dayjs())
      : ""

    return (
      <div>
        <div
          style={{
            fontWeight: 900,
            fontSize: "22px",
            paddingTop: "30px",
            paddingBottom: "15px",
          }}
        >
          Ticket Details
        </div>
        <div>Name: {customerName}</div>
        <div>Ticket: {typeName}</div>
        {!isValid && <div>Already checked-in: {formattedCheckInTime}</div>}
      </div>
    )
  }
}

export default IndexPage
