import * as React from "react"
import { useWindowSize } from "react-use"

const AutoResizeCanvas = React.forwardRef(
  ({ aspectRatio, style, padding = 20 }, ref) => {
    let { width } = useWindowSize()
    const isMobile = width < 768

    return (
      <canvas
        style={{
          width: isMobile ? "100vw" : "80%",
          height: isMobile ? "auto" : "80%",
          maxWidth: "800px",
          ...style,
        }}
        ref={ref}
      ></canvas>
    )
  }
)

export default AutoResizeCanvas
