import React, { PureComponent } from "react"
import firebase from "./firebase"
import { navigate } from "gatsby"
import Layout from "../components/layout"

const validEmails = [
  "martin@martinwheeler.com.au",
  "edda@undressrunways.com",
  "edda.hamar@gmail.com",
  "sunny@undressrunways.com",
  "ryan@undressrunways.com",
  "juju@undressrunways.com",
  "media@undressrunways.com",
]

const handleLoginRedirect = () => {
  localStorage.setItem("redirect-path", window.location.pathname)
  navigate("/login")
}

const withAuth = WrappedComponent => {
  class WithAuth extends PureComponent {
    state = {
      authUser: null,
    }

    componentDidMount() {
      this.setState(
        { authUser: JSON.parse(localStorage.getItem("authUser")) },
        () => {
          try {
            firebase.auth().onAuthStateChanged(user => {
              if (user && validEmails.includes(user.email)) {
                localStorage.setItem("authUser", JSON.stringify(user))
                this.setState({ authUser: user })
              } else {
                localStorage.removeItem("authUser")
                this.setState({ authUser: null })
                handleLoginRedirect()
              }
            })
          } catch (error) {
            localStorage.removeItem("authUser")
            this.setState({ authUser: null })
            handleLoginRedirect()
          }
        }
      )
    }

    render() {
      return this.state.authUser ? (
        <WrappedComponent {...this.props} />
      ) : (
        <Layout withNav loading={true}>
          Authentication...
        </Layout>
      )
    }
  }

  return WithAuth
}

export default withAuth
